import Vue from 'vue'
import Router from 'vue-router'
import beforeEnter from '@/utils'

const LoginPage = () => import('@/pages/LoginPage')
const DefaultLayout = () => import('@/layouts/DefaultLayout')
const UsersListPage = () => import('@/pages/user/UsersListPage')
const UserSearchAudiosPage = () => import('@/pages/history/UserSearchAudiosPage')
const AllSearchedAudiosPage = () => import('@/pages/history/AllSearchedAudiosPage')
const CompetitionsListPage = () => import('@/pages/competition/CompetitionsListPage')
const CompetitionsAddEditPage = () => import('@/pages/competition/CompetitionsAddEditPage')
const CompetitionsSummaryPage = () => import('@/pages/competition/CompetitionsSummaryPage')

const NotFoundPage = () => import('@/pages/NotFoundPage.vue')

Vue.use(Router)

export default new Router({
    mode: 'hash',
    linkActiveClass: 'open active',
    linkExactActiveClass: 'open active',
  routes: [
        {
            path: '/',
            name: 'Home',
            redirect: '/user',
            component: DefaultLayout,
            beforeEnter: beforeEnter,
            children: [
              {
                path: 'user',
                name: 'Users',
                redirect: '/user/list',
                beforeEnter: beforeEnter,
                component: {
                  render(c) {
                    return c('router-view');
                  }
                },
                children: [
                  {
                    path: 'list',
                    name: 'UsersListPage',
                    component: UsersListPage
                  }
                ]
              },
              {
                path: 'competitions',
                name: 'Competitions',
                redirect: '/competitions/list',
                beforeEnter: beforeEnter,
                component: {
                  render(c) {
                    return c('router-view')
                  }
                },
                children: [
                  {
                    path: 'list',
                    name: 'CompetitionsListPage',
                    component: CompetitionsListPage
                  },
                  {
                    path: 'add',
                    name: 'CompetitionsAdd',
                    component: CompetitionsAddEditPage
                  },
                  {
                    path: 'edit/:id',
                    name: 'CompetitionsEdit',
                    component: CompetitionsAddEditPage
                  },
                  {
                    path: 'summary/:id',
                    name: 'CompetitionsSummary',
                    component: CompetitionsSummaryPage
                  }
                ]
              },
              {
                path: 'history',
                name: 'History',
                redirect: '/history/list',
                beforeEnter: beforeEnter,
                component: {
                  render(c) {
                    return c('router-view');
                  }
                },
                children: [
                  {
                    path: 'list',
                    name: 'AllSearchedAudios',
                    component: AllSearchedAudiosPage
                  },
                  {
                    path: 'useraudios/:id',
                    name: 'UserSearchAudios',
                    component: UserSearchAudiosPage
                  }
                ]
              }
            ]
        },
        {
            path: '/login',
            name: 'Login',
            component: LoginPage
        },
        {
            path: '/404',
            name: 'NotFound',
            component: NotFoundPage
        },
        {
            path: '*',
            component: NotFoundPage
        }
    ]
})
